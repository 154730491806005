$(document).ready(function() {
  var answerArr = {};

  $('[data-toggle="popover"]').popover({html: true, placement: 'top', trigger: 'click'});

  $('.pop-link').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    if ($(this).children('i').hasClass('fa-question-circle')) {
      $(this).html('<i class="far fa-times-circle"></i>');
    }
    else {
      $(this).html('<i class="far fa-question-circle"></i>');
    }
  });

  $(window).on('click', function(e) {
    if ($('.popover.show').length > 0) {
      $('.popover.show').popover('hide');
      $('.pop-link').each(function(event) {
        $(this).html('<i class="far fa-question-circle"></i>');
      });
    }
  });

  $('button.answer').on('click', function(e) {
    var answer = $(e.target);
    var question = answer.closest('.question');
    answerArr[question.attr('data-value')] = answer.val();

    if (!answer.hasClass('selected')) {
      answer.siblings().removeClass('selected');
      answer.addClass('selected');

      // Check if already active questions
      if (question.nextAll('.question.active').length > 0) {
        summaryOut();
        question.nextAll('.question.active').fadeOut(400, function() {
          $(this).removeClass('focused');
          $(this).removeClass('active');
          goNext('#' + answer.attr('data-next'));
        });
      }
      else if ($('.done.active').length > 0) {
        $('.summary').fadeOut(400, function() {
          goNext('#' + answer.attr('data-next'));
        });
      }
      else {
        goNext('#' + answer.attr('data-next'));
      }
    }

  });

  $('button span').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();
    $(e.target).parent('button').click();
  });

  $('.intro button').on('click', function(e) {
    $(this).fadeOut(400, function() {
      $('#q1').addClass('active');
      $('#q1').addClass('focused');
      $('#q1').fadeIn(400);
      scrollTo('#q1');
      updateStatus();
    });
  });

  $('.view-model').on('click', function(e) {
    e.preventDefault();
    scrollTo('.model');
  });

  function goNext(id) {
    // Reset
    $('.done.active').hide();
    $('.done').removeClass('active');
    $('.question.active').removeClass('focused');
    $(id).find('.answer').removeClass('selected');

    $(id).addClass('focused');
    $(id).addClass('active');

    if (id.includes('done')) {
      $(id).show();
      summaryIn();
    }
    else {
      $(id).fadeIn(400);
    }
    updateStatus();
    scrollTo(id);
  }

  function summaryIn() {
    $('.summary').fadeIn(400);
    $('.videos').fadeIn(400);
    $('.model').fadeIn(400);
  }

  function summaryOut() {
    $('.summary').fadeOut(400);
    $('.videos').fadeOut(400);
    $('.model').fadeOut(400);
  }

  function scrollTo(element) {
    setTimeout(function() {
      var scrollto = $(element).offset().top - 30;
      $([document.documentElement, document.body]).animate({scrollTop: scrollto}, 800);
    }, 100);
  }

  function updateStatus() {
    var question;
    var questionid;
    var answer;

    $('.status-item').hide();

    $('.question.active').each(function(qindex) {
      question = $(this);
      questionid = question.attr('data-value').replace('q', '');

      $('.status-q' + questionid + ' .status-text').text(question.find('h2 .question-text').text());

      question.find('.answer.selected').each(function(aindex) {
        $('.status-intro').hide();
        answer = $(this);
        $('.status-a' + questionid + ' .status-text').html(answer.html());
        $('.status-q' + questionid).fadeIn(400);
        $('.status-a' + questionid).fadeIn(400);
      });
    });
  }

});
